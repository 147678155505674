:root {
    /* productui */
    --productui-display-skeleton-base: var(--greytransparent-150);
    --productui-display-skeleton-oncolor: var(--whitetransparent-100);
    --productui-sidebar: var(--greyopaque-50);
    --productui-meta-red: var(--red-500);
    --productui-meta-red-rgb: 227 68 50;
    --productui-meta-yellow: var(--yellow-500);
    --productui-meta-blue: var(--blue-500);
    --productui-meta-blue-rgb: 41 122 188;
    --productui-meta-orange: var(--orange-500);
    --productui-meta-orange-rgb: 226 115 18;
    --productui-meta-purple: var(--purple-500);
    --productui-meta-lime-green: var(--green-500);
    --productui-meta-lavender: var(--fuschia-500);

    /* theme colors */
    --productui-primary-theme-color: var(--red-500);
    --productui-actionable-primary-bg-onhover: var(--red-600);
    --productui-display-skeleton-color: var(--red-300);
    --productui-sidebar-actionable-quaternary-background-active: var(--red-200);
    --productui-display-color: var(--red-600);
    --productui-keyword-highlight-background: var(--red-300);

    /* productui background colors */
    --productui-background-light-red: var(--red-200);
    --productui-background-dark-red: var(--red-400);
    --productui-background-light-yellow: var(--yellow-200);
    --productui-background-dark-yellow: var(--yellow-400);
    --productui-background-light-green: var(--green-200);
    --productui-background-dark-green: var(--green-400);
    --productui-background-light-sea-salt: var(--sea-salt-200);
    --productui-background-dark-sea-salt: var(--sea-salt-400);

    --product-ui-display-onlight-skeleton: rgb(35 33 21 / 0.08);
    --product-ui-display-oncolor-skeleton: rgba(255, 255, 255, 0.3);

    /* box shadows ---------------- */
    --shadow-productui-app-window: 0 1px 7px -3px var(--greytransparent-150), 0 0 20px -4px
        var(--greytransparent-200);
    --shadow-productui-frame-inset: 0 0 0 0.5px var(--greytransparent-200) inset;

    --product-ui-z-index-overlay: 50;
    --product-ui-z-index-popup: 100;
    --product-ui-z-index-mouse: 500;
    --product-ui-z-index-shadow: 1000;
    --product-ui-z-index-border: 5000;
    --product-ui-z-index-controls: 6000;

    --product-ui-frame-inset-base: 32px;
    --product-ui-frame-inset-window-modal-open: 128px;
    --product-ui-frame-inset-overlay-view-base: 40px;
    --product-ui-frame-inset-overlay-view-top: 75px;
    --product-ui-frame-inset-window-overlay-view-open: 16px;

    /* Dividers */
    --product-ui-divider-secondary: #f5f5f5;

    /* Priorities */
    --product-ui-background-priority-4: #f2efed;
    --product-ui-background-priority-3: #dde6f9;
    --product-ui-background-priority-2: #fbe7ce;
    --product-ui-background-priority-1: #ffd6d3;

    --product-ui-priority-4: #999999;
    --product-ui-priority-3: #2a67e2;
    --product-ui-priority-2: #eb8909;
    --product-ui-priority-1: #dc4c3e;

    /* Borders */
    --product-ui-inputs-border-idle: #e6e6e6;
}
